<template>
    <div class="relative inline-block text-left mt-0.5">
        <div @click="onOpen">
            <span class="inline-block relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-500 hover:text-blue-400 cursor-pointer">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                </svg>
                <span class="absolute whitespace-nowrap top-0 right-0 h-4 w-4 inline-flex items-center justify-center px-1 py-0.5 text-2xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{{ attachmentsCount }}</span>
            </span>
        </div>
        <div class="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" v-if="isOpen" v-on-click-outside="onClickOutside">
            <div class="py-1 max-h-32 scroll-bar overflow-y-scroll" role="none">
                <template v-if="item.attachments && item.attachments.length">
                    <div class="flex items-center justify-between p-2 text-sm hover:bg-gray-100" v-for="attachment in item.attachments" :key="attachment.attachment_id" @click="onFileDownload(attachment)" :title="attachment.src_filename">
                        <span class="truncate text-sm">{{ attachment.src_filename }}</span>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-blue-500 ml-2 flex-shrink-0">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg> -->
                    </div>
                </template>
                <template v-else>
                    <div class="flex items-center justify-between p-2 text">No attachments</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Attachments",
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        onOpen() {
            this.isOpen = !this.isOpen;
        },
        onFileDownload(attachment) {
            // window.open(attachment.attachment_path);
            this.$modal.show("file-preview", { attachment });
            this.onClickOutside();
        },
        onClickOutside() {
            this.isOpen = false;
        },
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        attachmentsCount() {
            if (this.item?.attachments?.length > 0) {
                return this.item.attachments.length > 9 ? "9+" : this.item?.attachments?.length;
            }
            return 0;
        },
    },
};
</script>

<style lang="scss" scoped></style>
